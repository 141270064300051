<template>
  <div>
    <b-row>
      <b-col class="col-md-12">
        <SalesSummary
          :sales-data="salesData"
          :wallet-data="walletData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-6">
        <TravelerSummary
          v-if="isDataLoaded"
          :traveller-data="travellerData"
        />
      </b-col>
      <b-col class="col-md-6">
        <FlightSummary
          v-if="isDataLoaded"
          :flight-data="flightData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-12">
        <AirlineSalesTable
          :table-data="airlineSales"
          :data-loaded="isDataLoaded"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/libs/api'
import AirlineSalesTable from '@/views/dashboard/AirlineSalesTable.vue'
import FlightSummary from '@/views/dashboard/FlightSummary.vue'
import TravelerSummary from '@/views/dashboard/TravelerSummary.vue'
import SalesSummary from '@/views/dashboard/SalesSummary.vue'

export default {
  name: 'Dashboard',
  components: {
    AirlineSalesTable,
    FlightSummary,
    TravelerSummary,
    SalesSummary,
  },
  data() {
    return {
      airlineSales: [],
      walletData: {},
      salesData: {},
      flightData: null,
      travellerData: {},
      isDataLoaded: false,
    }
  },
  mounted() {
    this.getBizStat()
  },
  methods: {
    getBizStat() {
      api.postData('b2e_dashboard/biz_stat/', true, {})
        .then(response => {
          if (response.data.status) {
            this.airlineSales = response.data.result.airline_sale
            this.flightData = response.data.result.flight
            this.travellerData = response.data.result.traveller
            this.walletData = response.data.result.wallet
            this.salesData = response.data.result.sale
            this.isDataLoaded = true
          }
        }).catch()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
